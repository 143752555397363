

































import { Component, Prop } from 'vue-property-decorator'
import Posts from '~components/content/Posts.vue'
import SearchPagination from '~components/content/widgets/pagination/SearchPagination.vue'
import { ListingPage, ListingPageVariables } from '~base/mixins/listings'
import {
  VueMetaProperty,
  VueMetaInfo,
  ApolloOperationVariables,
} from '~base/common/types'
import DropDown from '~components/utils/DropDown.vue'

@Component<ListingPage & LocalityPage>({
  components: {
    Posts,
    SearchPagination,
    DropDown,
  },

  head(): VueMetaInfo {
    return {
      title: this.title,
      meta: this.meta,
      link: this.link,
    }
  },

  apollo: {
    search: {
      query: require('~base/graphql/queries/FetchPostsForLocality.gql'),
      variables(): ApolloOperationVariables {
        return this.variables
      },
      prefetch({ route }: any): ApolloOperationVariables {
        return {
          first: this.perPage,
          page: parseInt(route.params.page || '0'),
          localities: this.locality,
        }
      },
      result({ data }) {
        this.gotResult(data)
      },
    },
  },
})
export default class LocalityPage extends ListingPage {
  @Prop({ type: Array, required: true }) readonly locality!: string[]
  @Prop({ type: String, required: true }) readonly nicename!: string

  private township = this.nicename

  get sidebar() {
    return 'locality'
  }

  get localityList() {
    const localities = this.$store.getters['weather/townships']
    return ['Vorarlberg', ...localities]
  }

  onChange(e: string) {
    if (e !== 'Vorarlberg') {
      this.$store.dispatch('weather/changeRegion', e)
      const newLocality = this.$store.getters['weather/selectedRegion']
      this.$router.push(`/${newLocality}`)
    } else {
      this.$router.push('/heimat')
    }
  }

  mounted() {
    const category = this.locality.length > 1 ? 'heimat' : this.locality[0]
    this.$store.dispatch('ads/pageChanged', {
      pageType: 'index',
      category,
    })
    this.$store.dispatch('tracking/trackSearchPage', category)
  }

  loadMoreData() {
    const { endCursor } = this.search.pageInfo
    if (endCursor) {
      this.after = endCursor
      this.nextPage()
    }
  }

  get variables(): ListingPageVariables {
    return {
      ...super.variables,
      localities: this.locality,
    }
  }

  get title(): string {
    const title = `${this.nicename} - ${this.$root.$t('seo.title.locality')}`
    return title
  }

  get meta(): VueMetaProperty[] {
    const description = this.$root
      .$t('seo.description.locality', [this.nicename])
      .toString()

    return [
      ...super.meta,
      { hid: 'description', name: 'description', content: description },
      { hid: 'og:title', name: 'og:title', content: this.title },
      { hid: 'og:description', name: 'og:description', content: description },
    ]
  }

  get linkPrefix(): string {
    const linkPrefix = this.locality.length > 1 ? 'heimat' : this.locality[0]
    return `/${linkPrefix}`
  }
}
